import React, { Component } from "react";
import logo_catalx from "../../assets/images/logo_catalx.svg";

import catalx_main from "../../assets/images/catalx/catalx_main@2x.jpg";

class About extends Component {
  render() {
    return (
      <main className="page_detail about">
        <div className="intro">
          <div className="content_flex ">
            <img
              src={logo_catalx}
              className="company_logo catalx"
              alt="Best Buy Canada"
            />
            <h1 className="gradient_catalx">About</h1>

            <p className="">
              CatalX Exchange is a{" "}
              <span className="highlighted">
                cryptocurrecny trading platoform{" "}
              </span>
              targeted at the{" "}
              <span className="highlighted">Canadian market</span>, looking into
              expanding into security tokens offerings as well.
            </p>

            <div className="rapsheet">
              <div className="r_item">
                <h4>Timeframe</h4>
                <h3>2019 - 2020</h3>
              </div>
              <div className="r_item">
                <h4>Title</h4>
                <h3>Product Designer</h3>
              </div>
              <div className="r_item">
                <h4>Products</h4>
                <h3>Trading Platform, Marketing Site</h3>
              </div>
              <div className="r_item">
                <h4>Link</h4>
                <h3>
                  <a className="gradient_catalx" href="https://www.catalx.io">
                    catalx.io
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <figure>
          <img src={catalx_main} className="hero" alt="Best Buy Canada " />
        </figure>

        <div className="content">
          <section className="alternatebg">
            <div className="content_flex">
              <h3>Trustworthy Platform for Power Traders</h3>
              <p>
                The target user group at the start was to build a platform for
                people who have not traded cryptocurrencies before. Initially I
                created a system that was more conversational and easier for new
                users to get in to trading, but after some user research and
                feedback the company changed the target audience to power
                traders: a trading screen with all of the information user needs
                to make a decision buying or selling.
              </p>
            </div>
          </section>

          <section>
            <div className="content_flex">
              <h3>Development Team Work Flow</h3>
              <p>
                I documented everything in Confluence and Jira to communicated
                with developers and stakeholders. I created user flow diagrams
                with the team to stay aligned with archtectural decisions and to
                work in parallel while I produced the wireframes and mockups.
              </p>
            </div>
          </section>
        </div>
      </main>
    );
  }
}

export default About;
