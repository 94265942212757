import React, { Component } from "react";
/*import Navigation from "../navComponent/navigation";*/

class Footer extends Component {
  render() {
    return (
      <footer>
        <hr />
        <hr />
        <hr />

        <div className="container">
          <p>
            Made in Coquitlam 2020 by Jason Joo, learning{" "}
            <a href="https://reactjs.org/">react.</a>
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
