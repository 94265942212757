import React, { useEffect } from "react";
import Header from "./components/headerComponent/header";
import Footer from "./components/footerComponent/footer";
import Homepage from "./components/pages/homepage";
import About from "./components/pages/about";

import BestBuyCanada from "./components/pages/bestbuycanada";
import CatalX from "./components/pages/catalx";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import ReactGA from "react-ga";

import { createBrowserHistory } from "history";

import FloatingNavigation from "./components/floatingNavComponent/floatingnavigation";
/*import ScrollToTop from './components/scrollTopComponent/scrolltotop';*/
function ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

ReactGA.initialize("G-RRB04WW5L8");
ReactGA.pageview("/homepage");

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  return (
    <Router history={history}>
      <ScrollToTop>
        <div className="App">
          <FloatingNavigation />
          <Header />
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/BestBuyCanada" component={BestBuyCanada} />
            <Route exact path="/CatalX" component={CatalX} />
            <Route exact path="/About" component={About} />
          </Switch>

          <Footer />
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
