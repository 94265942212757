import React, { Component } from "react";
import logo_bby from "../../assets/images/logo_bby.svg";
import bby_designsystem1 from "../../assets/images/bby/forms@2x.jpg";
import bby_designsystem2 from "../../assets/images/bby/dialogs@2x.jpg";
import NextPage from "../../components/nextPageComponent/nextpage";

import bby_main from "../../assets/images/bby/bby_main@2x.jpg";
import bby_old from "../../assets/images/bby/shipping_old@2x.jpg";
import bby_new from "../../assets/images/bby/shipping_new@2x.jpg";
import bby_0 from "../../assets/images/bby/0_cart@2x.jpg";
import bby_1 from "../../assets/images/bby/1_shipping@2x.jpg";
import bby_2 from "../../assets/images/bby/2_payment@2x.jpg";
import bby_3 from "../../assets/images/bby/3_review@2x.jpg";
import bby_4 from "../../assets/images/bby/4_person@2x.jpg";
import bby_5 from "../../assets/images/bby/5_store@2x.jpg";
import bby_6 from "../../assets/images/bby/6_review@2x.jpg";

class BestBuyCanada extends Component {
  render() {
    return (
      <main className="page_detail bby">
        <div className="intro">
          <div className="content_flex ">
            {/* div className="logo_container">
              <img
                src={logo_bby}
                className="company_logo"
                alt="Best Buy Canada"
              />
            </div>*/}
            <h1 className="gradient bby">Best Buy Canada</h1>

            <p className="">
              Best Buy is a multinational consumer retailer specializing in
              electronics. As a UI designer in e-commerece product stream
              division, I worked on the transactional side of the new website on{" "}
              <span className="highlighted">Checkout</span> and{" "}
              <span className="highlighted">Accounts</span> teams.
            </p>

            <div className="rapsheet">
              <div className="r_item">
                <h4>Timeframe</h4>
                <h3>2016 - 2019</h3>
              </div>
              <div className="r_item">
                <h4>Role</h4>
                <h3>Senior UI Designer</h3>
              </div>
              <div className="r_item">
                <h4>Products</h4>
                <h3>Checkout / Accounts</h3>
              </div>
              <div className="r_item">
                <h4>Link</h4>
                <h3>
                  <a className="gradient bby" href="https://www.bestbuy.ca">
                    bestbuy.ca
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <figure>
          <img src={bby_main} className="hero" alt="Best Buy Canada " />
        </figure>

        <div className="content">
          <section className="alternatebg">
            <div className="content_flex">
              <h3>Purchasing Paths</h3>
              <h4>Checkout</h4>
              <p>
                From the competitor analysis, Best Buy had one of the most
                complicated cart and checkout processes due to the business
                rules and restrictions. The UX goal was to deliver the user
                efficiency of shopping while best informing any and every offer
                or options. I had to think of ways to display required items,
                bonus items, warranties, coupons, and shipments all at the same
                time.
              </p>
            </div>
            <div className="content_grid">
              <figure className="span_6">
                <img src={bby_0} alt="Shipping" />
                <figcaption>Cart</figcaption>
              </figure>
              <figure className="span_6">
                <img src={bby_1} alt="Shipping" />
                <figcaption>Shipping</figcaption>
              </figure>
              <figure className="span_6">
                <img src={bby_2} alt="Payment" />
                <figcaption>Payment</figcaption>
              </figure>
              <figure className="span_6">
                <img src={bby_3} alt="Review" />
                <figcaption>Review</figcaption>
              </figure>
            </div>
            <div className="content_flex">
              <h4>Reserve and Pick Up</h4>

              <p>
                Best Buy was one of the first stores in Canada to offer in-store
                pick up method–customers can reserve items online and pick up in
                store, saving time. The previous experience was very far off
                from ideal user journey as it was an add-on feature to the
                legacy site. I designed the process to be very similar to
                checkout so the user can change their method of purchase in
                cart, and set the future roadmap where it can be merged with
                Checkout.
              </p>
            </div>
            <div className="content_grid">
              <figure className="span_4">
                <img src={bby_5} alt="RPU Pickup Person" />
                <figcaption>Choose a store to pick the item up from</figcaption>
              </figure>
              <figure className="span_4">
                <img src={bby_4} alt="RPU Store" />
                <figcaption>State who will pick the item up</figcaption>
              </figure>
              <figure className="span_4">
                <img src={bby_6} alt="RPU Review" />
                <figcaption>
                  Review before going into the store to buy
                </figcaption>
              </figure>
            </div>
          </section>

          <section>
            <div className="content_flex">
              <h3>Design System</h3>
              <p>
                I was also on the design system team, responsible for creating
                and maintaining the design system. Every component we designed
                was made sure to adhere to the accessibilty standards.
              </p>
            </div>
            <div className="content_grid">
              <figure className="span_6">
                <img
                  src={bby_designsystem1}
                  alt="Forms - Best Buy Canada Design System"
                />

                <figcaption>Forms - Best Buy Canada Design System</figcaption>
              </figure>
              <figure className="span_6">
                <img
                  src={bby_designsystem2}
                  alt="Dialogs - Best Buy Canada Design System"
                />
                <figcaption>
                  Dialogs - Best Buy Canada Design System{" "}
                </figcaption>
              </figure>
            </div>
          </section>
          <section className="alternatebg">
            <div className="content_flex">
              <h3>New Branding</h3>
              <p>
                When the global rebrand for Best Buy came, we were ready–the
                design system team knew this was coming and we built our design
                system to be easily updatable with color and logo replacable.
              </p>
            </div>
            <div className="content_grid">
              <figure className="span_6">
                <img
                  src={bby_old}
                  className="image_grid"
                  alt="Best Buy Canada Design System - Forms"
                />
                <figcaption>Before Rebrand</figcaption>
              </figure>
              <figure className="span_6">
                <img
                  src={bby_new}
                  className="image_grid"
                  alt="Best Buy Canada Design System - Forms"
                />
                <figcaption>After Rebrand</figcaption>
              </figure>
            </div>
          </section>
        </div>
        <NextPage />
      </main>
    );
  }
}

export default BestBuyCanada;
