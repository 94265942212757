import React, { Component } from "react";
import logo_catalx from "../../assets/images/logo_catalx_v2.svg";
import NextPage from "../../components/nextPageComponent/nextpage";

import catalx_main from "../../assets/images/catalx/catalx_main@2x.jpg";
import catalx_trade from "../../assets/images/catalx/trade@2x.png";

import flow_bank from "../../assets/images/catalx/flow_bank.svg";
import flow_trade from "../../assets/images/catalx/flow_trade.svg";

import catalx_screens1 from "../../assets/images/catalx/dashboard@2x.png";
import catalx_screens2 from "../../assets/images/catalx/accounts@2x.png";
import catalx_screens3 from "../../assets/images/catalx/wallets@2x.png";
import catalx_screens4 from "../../assets/images/catalx/history@2x.png";

import catalx_home from "../../assets/images/catalx/home@2x.png";
import catalx_team from "../../assets/images/catalx/team@2x.png";

class CatalX extends Component {
  render() {
    return (
      <main className="page_detail catalx">
        <div className="intro">
          <div className="content_flex ">
            {/* <div className="logo_container">
              <img
                src={logo_catalx}
                className="company_logo catalx"
                alt="Best Buy Canada"
              />
            </div>*/}
            <h1 className="gradient catalx">CatalX</h1>

            <p className="">
              CatalX Exchange is a{" "}
              <span className="highlighted">
                cryptocurrency trading platform{" "}
              </span>
              targeted at the{" "}
              <span className="highlighted">Canadian market</span>, looking into
              expanding into security tokens offerings as well.
            </p>

            <div className="rapsheet">
              <div className="r_item">
                <h4>Timeframe</h4>
                <h3>2019 - 2020</h3>
              </div>
              <div className="r_item">
                <h4>Title</h4>
                <h3>Product Designer</h3>
              </div>
              <div className="r_item">
                <h4>Products</h4>
                <h3>Trading Platform, Marketing Site</h3>
              </div>
              <div className="r_item">
                <h4>Link</h4>
                <h3>
                  <a className="gradient catalx" href="https://www.catalx.io">
                    catalx.io
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <figure>
          <img src={catalx_main} className="hero" alt="Best Buy Canada " />
        </figure>

        <div className="content">
          <section className="alternatebg">
            <div className="content_flex">
              <h3>Trustworthy Platform for Power Traders</h3>
              <p>
                The target user group at the start was to build a platform for
                people who have not traded cryptocurrencies before. Initially I
                created a system that was more conversational and easier for new
                users to get in to trading, but after some user research and
                feedback the company changed the target audience to power
                traders: a trading screen with all of the information user needs
                to make a decision buying or selling.
              </p>
            </div>
            <div className="content_grid">
              <figure className="span_12">
                <img
                  src={catalx_trade}
                  className="portfolio_image"
                  alt="CatalX Trading Platform"
                />
                <figcaption>Trade</figcaption>
              </figure>
              <figure className="span_6">
                <img
                  src={catalx_screens1}
                  className="portfolio_image"
                  alt="CatalX Trading Platform"
                />
                <figcaption>Dashboard</figcaption>
              </figure>
              <figure className="span_6">
                <img
                  src={catalx_screens2}
                  className="portfolio_image"
                  alt="CatalX Trading Platform"
                />
                <figcaption>Accounts</figcaption>
              </figure>
              <figure className="span_6">
                <img
                  src={catalx_screens3}
                  className="portfolio_image"
                  alt="CatalX Trading Platform"
                />
                <figcaption>Wallets</figcaption>
              </figure>
              <figure className="span_6">
                <img
                  src={catalx_screens4}
                  className="portfolio_image"
                  alt="CatalX Trading Platform"
                />
                <figcaption>Trade History</figcaption>
              </figure>
            </div>
            <div className="content_flex">
              <p>
                One of the business requirement was to make sure the designs can
                adapt to security offerings in the future.
              </p>
            </div>
          </section>

          <section>
            <div className="content_flex">
              <h3>Development Team Work Flow</h3>
              <p>
                I documented everything in Confluence and Jira to communicate
                with developers and stakeholders. I created user flow diagrams
                with the team to stay aligned with architectural decisions, and
                to work in parallel while I produced the wireframes and mockups.
              </p>
            </div>
            <div className="content_grid">
              <figure className="span_6">
                <img
                  src={flow_bank}
                  className="portfolio_image"
                  alt="CatalX Trading Platform"
                />
                <figcaption>Wallet User Flows</figcaption>
              </figure>
              <figure className="span_6">
                <img
                  src={flow_trade}
                  className="portfolio_image"
                  alt="CatalX Trading Platform"
                />
                <figcaption>Trade User Flows</figcaption>
              </figure>
            </div>
          </section>

          <section className="alternatebg">
            <div className="content_flex">
              <h3>Marketing</h3>
              <h4>Content Pages</h4>
              <p>
                Cryptocurrency exchange is a finicky market, especially in
                Canada with precedents of shady businesses and closures. To
                counter this the business requirement was that the brand be
                serious fintech company that is bold. I designed all of our
                content pages and marketing pages trying to express such.
              </p>
            </div>
            <div className="content_grid">
              <figure className="span_6">
                <img
                  src={catalx_home}
                  className="portfolio_image"
                  alt="CatalX Trading Platform"
                />
                <figcaption>Homepage</figcaption>
              </figure>
              <figure className="span_6">
                <img
                  src={catalx_team}
                  className="portfolio_image"
                  alt="CatalX Trading Platform"
                />
                <figcaption>About us page</figcaption>
              </figure>
            </div>
          </section>
        </div>
        <NextPage />
      </main>
    );
  }
}

export default CatalX;
