import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "../navComponent/navigation";
class Header extends Component {
  render() {
    return (
      <header>
        <div className="topbar">
          <Link to="/">
            <svg
              width="37px"
              height="20px"
              viewBox="0 0 37 20"
              className="logo"
            >
              <path
                d="M19.7476061,0.000747498228 L19.7476061,3.40143898 C21.5548342,1.32075408 24.1937761,0.000747498228 27.1421803,0.000747498228 C32.5865323,0.000747498228 37,4.47822651 37,10.0007597 C37,15.5232658 32.5858155,20 27.1414636,20 L27.1414636,20 L27.1414636,17.5007796 C31.2254891,17.5007796 34.5353211,14.1426619 34.5353211,10.000034 C34.5353211,5.85741517 31.2262059,2.49930659 27.1421803,2.49930659 C23.0582444,2.49930659 19.7476061,5.85741517 19.7476061,10.000034 L19.7476061,10.000034 L19.7476061,20 L17.2830167,20 L17.2838791,16.5775326 C15.4770949,18.6750474 12.8205636,20 9.85851761,20 C4.4141298,20 1.24344979e-13,15.5232658 1.24344979e-13,10.0007597 L1.24344979e-13,10.0007597 L2.46462425,10.0007597 C2.46462425,14.1425712 5.77450999,17.4999631 9.85851761,17.4999631 C13.9425431,17.4999631 17.2523751,14.1418455 17.2523751,10.000034 L17.2523751,10.000034 L17.2523751,0 L19.7476061,0.000747498228 Z"
                id="Combined-Shape"
              ></path>
            </svg>
          </Link>

          <Navigation />
        </div>
      </header>
    );
  }
}

export default Header;
