import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FileTextIcon } from "@modulz/radix-icons";
import { Pencil2Icon } from "@modulz/radix-icons";
import { ExternalLinkIcon } from "@modulz/radix-icons";
class Homepage extends Component {
  render() {
    return (
      <main className="page_home">
        <div className="intro">
          <div className="content_flex ">
            <div className="logo_container">&nbsp;</div>
            <h1 className="gradient_jjoo">Jason Joo</h1>

            <p>
              I am a product designer based in
              <span className="highlighted"> Vancouver</span>, currently at{" "}
              <span className="gradient mastercard">
                <a href="https://brighterion.com">
                  Brighterion Mastercard
                  <ExternalLinkIcon />
                </a>
              </span>{" "}
              , as <span className="highlighted">
                {" "}
                lead UX/UI designer
              </span>.{" "}
            </p>
            <p>
              Previously I was at{" "}
              <Link to="/Catalx">
                <span className="gradient catalx">CatalX Exchange&nbsp;</span>
              </Link>
              leading the product team, and{" "}
              <Link to="/BestBuyCanada">
                <span className="gradient bby">Best Buy Canada </span>&nbsp;
              </Link>
              making the Checkout and Accounts experiences smoother.
            </p>
            <hr />
            <div className="otherlinks">
              <a href="/resume_JasonJoo_2021.pdf" target="_blank">
                <FileTextIcon />
                View CV
              </a>
              <a href="mailto:jaeisix@gmail.com">
                <Pencil2Icon />
                Send an email
              </a>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Homepage;
