import React from "react";
import Navigation from "../../components/navComponent/navigation";
function NextPage() {
  return (
    <div className="next">
      <h2>Next – </h2>
      <Navigation />
    </div>
  );
}

export default NextPage;
