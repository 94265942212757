import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    <nav>
      {/*<Link to="/About">About</Link>*/}
      <NavLink activeClassName="activeNav" to="/CatalX">
        CatalX{" "}
      </NavLink>
      <NavLink activeClassName="activeNav" to="/BestBuyCanada">
        Best Buy
      </NavLink>
    </nav>
  );
}

export default Navigation;
